import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { syncStageTheme } from 'common/theme/syncStage';
import { ResetCSS } from 'common/assets/css/style';
import { GlobalStyle, ContentWrapper } from 'containers/SyncStage/syncStage.style';

import Seo from 'components/seo';
import TermsSection from 'containers/SyncStage/DeveloperTerms';

const Terms = () => {
  return (
    <ThemeProvider theme={syncStageTheme}>
      <Fragment>
        <Seo title="SyncStage | Ultra Fast Audio Pipeline" />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <TermsSection />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default Terms;
