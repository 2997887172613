import styled from 'styled-components';
import FirstBg from 'common/assets/image/syncStage/pattern-1@2x.png';

const TermsSectionWrapper = styled.section`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    radial-gradient(
      89.85% 105.84% at 44.89% 3.77%,
      #00fff0 0%,
      #00c2ff 17.71%,
      #004cdf 40.63%,
      #321497 58.85%,
      #9800cd 81.25%,
      #cd00a0 100%
    );
  z-index: 0;
  padding: 0 200px;

  .first-bg {
    background-image: url(${FirstBg});
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 1800px;
  }

  .back-link {
    img {
      margin: 60px 0 0 -80px;
    }
  }

  @media (max-width: 990px) {
    padding: 0 150px;
    .back-link {
      img {
        margin: 60px 0 0 -60px;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 0 60px;
    .back-link {
      img {
        margin: 60px 0 0 -40px;
      }
    }
  }
  @media (max-width: 575px) {
    padding: 0 20px;
    .back-link {
      img {
        margin: 60px 0 0 0;
      }
    }
  }

  .lst-kix_m8vk67dcdt8m-3 > li {
    counter-increment: lst-ctn-kix_m8vk67dcdt8m-3;
  }

  .lst-kix_ug64bpjsjkfu-3 > li {
    counter-increment: lst-ctn-kix_ug64bpjsjkfu-3;
  }

  .lst-kix_uo6j2t9nf7h5-2 > li {
    counter-increment: lst-ctn-kix_uo6j2t9nf7h5-2;
  }

  .lst-kix_m8vk67dcdt8m-4 > li {
    counter-increment: lst-ctn-kix_m8vk67dcdt8m-4;
  }

  ol.lst-kix_uo6j2t9nf7h5-1.start {
    counter-reset: lst-ctn-kix_uo6j2t9nf7h5-1 0;
  }

  ol.lst-kix_m8vk67dcdt8m-2.start {
    counter-reset: lst-ctn-kix_m8vk67dcdt8m-2 0;
  }

  .lst-kix_m8vk67dcdt8m-2 > li {
    counter-increment: lst-ctn-kix_m8vk67dcdt8m-2;
  }

  .lst-kix_ug64bpjsjkfu-4 > li {
    counter-increment: lst-ctn-kix_ug64bpjsjkfu-4;
  }

  ol.lst-kix_ug64bpjsjkfu-7.start {
    counter-reset: lst-ctn-kix_ug64bpjsjkfu-7 0;
  }

  .lst-kix_ug64bpjsjkfu-1 > li {
    counter-increment: lst-ctn-kix_ug64bpjsjkfu-1;
  }

  .lst-kix_uo6j2t9nf7h5-1 > li {
    counter-increment: lst-ctn-kix_uo6j2t9nf7h5-1;
  }

  ol.lst-kix_m8vk67dcdt8m-5.start {
    counter-reset: lst-ctn-kix_m8vk67dcdt8m-5 0;
  }

  .lst-kix_uo6j2t9nf7h5-4 > li {
    counter-increment: lst-ctn-kix_uo6j2t9nf7h5-4;
  }

  .lst-kix_m8vk67dcdt8m-6 > li {
    counter-increment: lst-ctn-kix_m8vk67dcdt8m-6;
  }

  .lst-kix_m8vk67dcdt8m-0 > li {
    counter-increment: lst-ctn-kix_m8vk67dcdt8m-0;
  }

  .lst-kix_uo6j2t9nf7h5-8 > li:before {
    content: '' counter(lst-ctn-kix_uo6j2t9nf7h5-8, lower-roman) '. ';
  }

  ol.lst-kix_ug64bpjsjkfu-1.start {
    counter-reset: lst-ctn-kix_ug64bpjsjkfu-1 0;
  }

  .lst-kix_uo6j2t9nf7h5-4 > li:before {
    content: '' counter(lst-ctn-kix_uo6j2t9nf7h5-4, lower-latin) '. ';
  }

  .lst-kix_uo6j2t9nf7h5-5 > li:before {
    content: '' counter(lst-ctn-kix_uo6j2t9nf7h5-5, lower-roman) '. ';
  }

  .lst-kix_uo6j2t9nf7h5-3 > li:before {
    content: '' counter(lst-ctn-kix_uo6j2t9nf7h5-3, decimal) '. ';
  }

  .lst-kix_uo6j2t9nf7h5-6 > li:before {
    content: '' counter(lst-ctn-kix_uo6j2t9nf7h5-6, decimal) '. ';
  }

  .lst-kix_uo6j2t9nf7h5-7 > li:before {
    content: '' counter(lst-ctn-kix_uo6j2t9nf7h5-7, lower-latin) '. ';
  }

  ol.lst-kix_m8vk67dcdt8m-4.start {
    counter-reset: lst-ctn-kix_m8vk67dcdt8m-4 0;
  }

  ol.lst-kix_uo6j2t9nf7h5-6.start {
    counter-reset: lst-ctn-kix_uo6j2t9nf7h5-6 0;
  }

  .lst-kix_uo6j2t9nf7h5-6 > li {
    counter-increment: lst-ctn-kix_uo6j2t9nf7h5-6;
  }

  .lst-kix_uo6j2t9nf7h5-1 > li:before {
    content: '' counter(lst-ctn-kix_uo6j2t9nf7h5-1, lower-latin) '. ';
  }

  .lst-kix_ug64bpjsjkfu-2 > li {
    counter-increment: lst-ctn-kix_ug64bpjsjkfu-2;
  }

  .lst-kix_uo6j2t9nf7h5-0 > li:before {
    content: '' counter(lst-ctn-kix_uo6j2t9nf7h5-0, decimal) '. ';
  }

  .lst-kix_uo6j2t9nf7h5-2 > li:before {
    content: '' counter(lst-ctn-kix_uo6j2t9nf7h5-2, lower-roman) '. ';
  }

  .lst-kix_uo6j2t9nf7h5-3 > li {
    counter-increment: lst-ctn-kix_uo6j2t9nf7h5-3;
  }

  .lst-kix_uo6j2t9nf7h5-0 > li {
    counter-increment: lst-ctn-kix_uo6j2t9nf7h5-0;
  }

  ol.lst-kix_ug64bpjsjkfu-8.start {
    counter-reset: lst-ctn-kix_ug64bpjsjkfu-8 0;
  }

  ol.lst-kix_uo6j2t9nf7h5-0.start {
    counter-reset: lst-ctn-kix_uo6j2t9nf7h5-0 0;
  }

  .lst-kix_ug64bpjsjkfu-5 > li {
    counter-increment: lst-ctn-kix_ug64bpjsjkfu-5;
  }

  ol.lst-kix_uo6j2t9nf7h5-7.start {
    counter-reset: lst-ctn-kix_uo6j2t9nf7h5-7 0;
  }

  .lst-kix_ug64bpjsjkfu-8 > li {
    counter-increment: lst-ctn-kix_ug64bpjsjkfu-8;
  }

  ol.lst-kix_ug64bpjsjkfu-2.start {
    counter-reset: lst-ctn-kix_ug64bpjsjkfu-2 0;
  }

  .lst-kix_ug64bpjsjkfu-5 > li:before {
    content: '' counter(lst-ctn-kix_ug64bpjsjkfu-5, lower-roman) '. ';
  }

  .lst-kix_ug64bpjsjkfu-4 > li:before {
    content: '' counter(lst-ctn-kix_ug64bpjsjkfu-4, lower-latin) '. ';
  }

  .lst-kix_ug64bpjsjkfu-6 > li:before {
    content: '' counter(lst-ctn-kix_ug64bpjsjkfu-6, decimal) '. ';
  }

  .lst-kix_uo6j2t9nf7h5-7 > li {
    counter-increment: lst-ctn-kix_uo6j2t9nf7h5-7;
  }

  .lst-kix_m8vk67dcdt8m-8 > li:before {
    content: '' counter(lst-ctn-kix_m8vk67dcdt8m-8, lower-roman) '. ';
  }

  .lst-kix_m8vk67dcdt8m-6 > li:before {
    content: '' counter(lst-ctn-kix_m8vk67dcdt8m-6, decimal) '. ';
  }

  .lst-kix_ug64bpjsjkfu-1 > li:before {
    content: '' counter(lst-ctn-kix_ug64bpjsjkfu-1, lower-latin) '. ';
  }

  ol.lst-kix_m8vk67dcdt8m-3.start {
    counter-reset: lst-ctn-kix_m8vk67dcdt8m-3 0;
  }

  .lst-kix_m8vk67dcdt8m-5 > li:before {
    content: '' counter(lst-ctn-kix_m8vk67dcdt8m-5, lower-roman) '. ';
  }

  .lst-kix_m8vk67dcdt8m-7 > li:before {
    content: '' counter(lst-ctn-kix_m8vk67dcdt8m-7, lower-latin) '. ';
  }

  .lst-kix_ug64bpjsjkfu-2 > li:before {
    content: '' counter(lst-ctn-kix_ug64bpjsjkfu-2, lower-roman) '. ';
  }

  .lst-kix_ug64bpjsjkfu-3 > li:before {
    content: '' counter(lst-ctn-kix_ug64bpjsjkfu-3, decimal) '. ';
  }

  ol.lst-kix_uo6j2t9nf7h5-8.start {
    counter-reset: lst-ctn-kix_uo6j2t9nf7h5-8 0;
  }

  .lst-kix_m8vk67dcdt8m-2 > li:before {
    content: '' counter(lst-ctn-kix_m8vk67dcdt8m-2, lower-roman) '. ';
  }

  .lst-kix_m8vk67dcdt8m-1 > li:before {
    content: '' counter(lst-ctn-kix_m8vk67dcdt8m-1, lower-latin) '. ';
  }

  .lst-kix_m8vk67dcdt8m-3 > li:before {
    content: '' counter(lst-ctn-kix_m8vk67dcdt8m-3, decimal) '. ';
  }

  .lst-kix_m8vk67dcdt8m-0 > li:before {
    content: '' counter(lst-ctn-kix_m8vk67dcdt8m-0, decimal) '. ';
  }

  .lst-kix_m8vk67dcdt8m-4 > li:before {
    content: '' counter(lst-ctn-kix_m8vk67dcdt8m-4, lower-latin) '. ';
  }

  .lst-kix_m8vk67dcdt8m-8 > li {
    counter-increment: lst-ctn-kix_m8vk67dcdt8m-8;
  }

  ol.lst-kix_m8vk67dcdt8m-6.start {
    counter-reset: lst-ctn-kix_m8vk67dcdt8m-6 0;
  }

  .lst-kix_ug64bpjsjkfu-8 > li:before {
    content: '' counter(lst-ctn-kix_ug64bpjsjkfu-8, lower-roman) '. ';
  }

  .lst-kix_ug64bpjsjkfu-7 > li:before {
    content: '' counter(lst-ctn-kix_ug64bpjsjkfu-7, lower-latin) '. ';
  }

  ol.lst-kix_ug64bpjsjkfu-6.start {
    counter-reset: lst-ctn-kix_ug64bpjsjkfu-6 0;
  }

  ol.lst-kix_uo6j2t9nf7h5-2.start {
    counter-reset: lst-ctn-kix_uo6j2t9nf7h5-2 0;
  }

  ol.lst-kix_ug64bpjsjkfu-3.start {
    counter-reset: lst-ctn-kix_ug64bpjsjkfu-3 0;
  }

  .lst-kix_ug64bpjsjkfu-7 > li {
    counter-increment: lst-ctn-kix_ug64bpjsjkfu-7;
  }

  ol.lst-kix_ug64bpjsjkfu-0.start {
    counter-reset: lst-ctn-kix_ug64bpjsjkfu-0 0;
  }

  .lst-kix_m8vk67dcdt8m-7 > li {
    counter-increment: lst-ctn-kix_m8vk67dcdt8m-7;
  }

  ol.lst-kix_uo6j2t9nf7h5-5.start {
    counter-reset: lst-ctn-kix_uo6j2t9nf7h5-5 0;
  }

  .lst-kix_ug64bpjsjkfu-0 > li:before {
    content: '' counter(lst-ctn-kix_ug64bpjsjkfu-0, decimal) '. ';
  }

  .lst-kix_ug64bpjsjkfu-6 > li {
    counter-increment: lst-ctn-kix_ug64bpjsjkfu-6;
  }

  .lst-kix_ug64bpjsjkfu-0 > li {
    counter-increment: lst-ctn-kix_ug64bpjsjkfu-0;
  }

  ol.lst-kix_m8vk67dcdt8m-1.start {
    counter-reset: lst-ctn-kix_m8vk67dcdt8m-1 0;
  }

  ol.lst-kix_uo6j2t9nf7h5-3.start {
    counter-reset: lst-ctn-kix_uo6j2t9nf7h5-3 0;
  }

  ol.lst-kix_m8vk67dcdt8m-8.start {
    counter-reset: lst-ctn-kix_m8vk67dcdt8m-8 0;
  }

  ol.lst-kix_ug64bpjsjkfu-4.start {
    counter-reset: lst-ctn-kix_ug64bpjsjkfu-4 0;
  }

  .lst-kix_uo6j2t9nf7h5-5 > li {
    counter-increment: lst-ctn-kix_uo6j2t9nf7h5-5;
  }

  .lst-kix_m8vk67dcdt8m-1 > li {
    counter-increment: lst-ctn-kix_m8vk67dcdt8m-1;
  }

  .lst-kix_m8vk67dcdt8m-5 > li {
    counter-increment: lst-ctn-kix_m8vk67dcdt8m-5;
  }

  ol.lst-kix_ug64bpjsjkfu-5.start {
    counter-reset: lst-ctn-kix_ug64bpjsjkfu-5 0;
  }

  ol.lst-kix_uo6j2t9nf7h5-4.start {
    counter-reset: lst-ctn-kix_uo6j2t9nf7h5-4 0;
  }

  ol.lst-kix_m8vk67dcdt8m-7.start {
    counter-reset: lst-ctn-kix_m8vk67dcdt8m-7 0;
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }

  .lst-kix_uo6j2t9nf7h5-8 > li {
    counter-increment: lst-ctn-kix_uo6j2t9nf7h5-8;
  }

  ol.lst-kix_m8vk67dcdt8m-0.start {
    counter-reset: lst-ctn-kix_m8vk67dcdt8m-0 0;
  }

  ol {
    margin: 0;
    padding: 0;
  }
  ol {
    list-style-type: circle;
  }
  table td,
  table th {
    padding: 0;
  }

  .c12 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;

    font-style: normal;
  }

  .c0 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;

    font-style: normal;
  }

  .c14 {
    padding-top: 0pt;
    padding-bottom: 17pt;
    line-height: 1.2;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c5 {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;

    font-style: normal;
  }

  .c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c13 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: right;
  }

  .c8 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 23pt;

    font-style: normal;
  }

  .c15 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;

    text-decoration: underline;
  }

  .c10 {
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
  }

  .c7 {
    padding: 0;
    margin: 0;
  }

  .c3 {
    margin-left: 36pt;
    padding-left: 0pt;
  }

  .c9 {
    color: #36dbfd;
    text-decoration: inherit;
  }

  .c6 {
    font-weight: 700;
  }

  .c2 {
    height: 11pt;
  }

  .title {
    padding-top: 0pt;

    font-size: 26pt;
    padding-bottom: 3pt;

    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .subtitle {
    padding-top: 0pt;
    font-size: 15pt;
    padding-bottom: 16pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  li {
    font-size: 11pt;
  }

  p {
    margin: 0;

    font-size: 11pt;
  }

  h3 {
    padding-top: 16pt;

    font-size: 14pt;
    padding-bottom: 4pt;

    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h4 {
    padding-top: 14pt;

    font-size: 12pt;
    padding-bottom: 4pt;

    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h5 {
    padding-top: 12pt;

    font-size: 11pt;
    padding-bottom: 4pt;

    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h6 {
    padding-top: 12pt;

    font-size: 11pt;
    padding-bottom: 4pt;

    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .contact {
    color: #36dbfd;
    cursor: pointer;
    font-weight: 600;
  }
`;

export default TermsSectionWrapper;
