import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import TermsSectionWrapper from './terms.style';
import Text from 'common/components/Text';
import BackIcon from 'common/assets/image/syncStage/back.png';
import { handleContact } from 'common/handlers';
import { Modal } from '@redq/reuse-modal';

// TODO: The HTML content is exported from Google Docs. We need to find a nicer way to export HTML.
const TermsSection = ({ title, content }) => {
  return (
    <TermsSectionWrapper>
      <Modal />
      <Container fullWidth noGutter>
        <div className="first-bg" />
        <a href="/" className="back-link">
          <img src={BackIcon} alt="Back" />
        </a>
        <Box>
          <Heading {...title} content="OPEN SESAME DEVELOPER TERMS OF USE AGREEMENT" />
        </Box>
        <Box>
          <Text {...content}>
            <React.Fragment>
              <p className="c1">
                <span className="c0">
                  PLEASE READ THIS CAREFULLY. IF YOU DO NOT AGREE TO THESE TERMS, YOU ARE NOT AUTHORIZED TO DOWNLOAD OR
                  USE THE OPEN SESAME API OR SDK (EACH AS DEFINED BELOW).
                </span>
              </p>
              <p className="c1 c2">
                <span className="c0"></span>
              </p>
              <p className="c1">
                <span className="c0">
                  THIS DEVELOPER TERMS OF USE AGREEMENT (THIS &ldquo;AGREEMENT&ldquo;) IS A LEGAL AGREEMENT BETWEEN OPEN
                  SESAME MEDIA INC. (“OPEN SESAME” OR “WE”) AND EITHER YOU INDIVIDUALLY (IF YOU ARE AGREEING TO IT IN
                  YOUR PERSONAL CAPACITY) OR, IF YOU ARE AUTHORIZED TO DOWNLOAD THE OPEN SESAME API AND/OR SDK ON BEHALF
                  OF YOUR ORGANIZATION, THE ENTITY FOR WHOSE BENEFIT YOU ACT (&ldquo;YOU&ldquo;). OPEN SESAME OWNS AND
                  OPERATES THE OPEN SESAME WEBSITE THROUGH WHICH THE API AND SDK ARE MADE AVAILABLE (THE “SITE”) AND THE
                  [OPEN SESAME SYNCTSTAGE LOW-LATENCY SYNCHRONOUS AUDIO COMMUNICATION PLATFORM, UNDERLYING TECHNOLOGY,
                  AND RELATED SERVICES] (COLLECTIVELY, THE “SERVICE”).
                </span>
              </p>
              <p className="c1 c2">
                <span className="c0"></span>
              </p>
              <p className="c1">
                <span className="c0">
                  PLEASE BE AWARE THAT SECTION 23 OF THIS AGREEMENT, BELOW, CONTAINS PROVISIONS GOVERNING HOW CLAIMS
                  THAT WE HAVE AGAINST EACH OTHER ARE RESOLVED. IN PARTICULAR, IT CONTAINS AN ARBITRATION AGREEMENT
                  WHICH WILL, WITH LIMITED EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO BINDING AND FINAL
                  ARBITRATION ON AN INDIVIDUAL BASIS, RATHER THAN JURY TRIALS OR CLASS ACTION LAWSUITS, AND ALSO LIMITS
                  THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.
                </span>
              </p>
              <p className="c1 c2">
                <span className="c0"></span>
              </p>
              <p className="c1">
                <span className="c0">
                  BY DOWNLOADING, INSTALLING, OR USING THE API OR SDK, YOU ARE AGREEING TO BE BOUND BY THE TERMS OF THIS
                  AGREEMENT. IF YOU HAVE ANY QUESTIONS OR CONCERNS ABOUT THE TERMS OF THIS AGREEMENT, PLEASE CONTACT US
                  USING{' '}
                  <span className="contact" onClick={handleContact}>
                    CONTACT FORM
                  </span>
                </span>
              </p>
              <p className="c1 c2">
                <span className="c0"></span>
              </p>
              <p className="c1 c2">
                <span className="c5 c4"></span>
              </p>
              <p className="c1">
                <span className="c0">
                  This Agreement governs your access to, and use of the Open Sesame application programming interface
                  (“API”) and software development kit (“SDK”) that Open Sesame makes generally available to its
                  customers for use in connection with the Service. The API is an interface designed to facilitate the
                  connection between your Developer Application and the Service. As used herein “Developer Application”
                  means any application program developed exclusively by you. Develop Application includes, but is not
                  limited to, any application whether standalone, embedded into another product, or offered as a
                  software-as-a-service or similar hosted service. The SDK is a set of development tools and
                  instructions designed to enable you to build interoperability between your Developer Application and
                  the Service. References to the API and SDK include associated scripts, specifications, procedures,
                  protocols and documentation provided by Open Sesame. The API and/or SDK may be delivered with certain
                  items of independent, third-party code that are licensed under separate terms provided by the
                  applicable authors or licensors (“Third Party Code”). This Third Party Code is licensed under the
                  terms of the license that accompanies such Third Party Code. Nothing in this Agreement limits your
                  rights under, or grants you rights that supersede, the terms and conditions of any applicable license
                  for any Third Party Code delivered with the Licensed Products. None of the terms of this Agreement
                  apply to such Third Party Code.
                </span>
              </p>
              <p className="c1 c2">
                <span className="c0"></span>
              </p>
              <p className="c1 c2">
                <span className="c5 c4"></span>
              </p>
              <ol className="c7 lst-kix_uo6j2t9nf7h5-0 start" start="1">
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    License. Subject to the terms and conditions of this Agreement, Open Sesame hereby grants you a
                    limited, non-exclusive, non-transferable, non-sublicensable right and license to access and use the
                    API or SDK solely to (i) develop interoperability between your Developer Application(s) and the
                    Service in accordance with the documentation (the “Purpose”). You acknowledge and agree that you
                    have no rights to any upgrades, modifications, enhancements or revisions that Open Sesame may make
                    to the API or SDK. You agree that we have no obligation to provide any support or engineering
                    assistance of any sort to you or your end users unless we otherwise agree in writing. You
                    acknowledge that we and our suppliers retain all right, title and interest in and to the original,
                    and any copies, of the Service, API and SDK and to the services enabled by the Service, API and SDK.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Restrictions. You may not use the API, SDK or Service to: (i) design or develop anything other than
                    an application consistent with the Purpose; (ii) make copies of the API or SDK; (iii) modify, create
                    derivative works of, port, translate, reverse engineer, reverse compile, or disassemble or otherwise
                    attempt to derive, reconstruct, identify or discover any source code, underlying ideas, or
                    algorithms of the API, SDK, Service, or any portion thereof, except as permitted by applicable law;
                    (iv) distribute, publish, sell, transfer, assign, lease, rent, lend, or sublicense or make available
                    to any third party as part of any time-sharing or service bureau arrangement either in whole or part
                    the API, SDK or Service to any third party except as may expressly be permitted herein; (v)
                    redistribute any component of the API or SDK except as may expressly be permitted herein; (vi)
                    remove or otherwise obfuscate any proprietary notices or labels from the API, SDK or Service; (vii)
                    use the API, SDK or Service for comparison or benchmarking purposes against third party products or
                    services; (viii) use the API, SDK or Service to build a competitive product or service; or (ix)
                    cause any part of the API, SDK or Service to be placed in the public domain. You may not access the
                    Service with software or means other than the API or SDK. You may not use the API, SDK or Service
                    except in accordance with applicable laws and regulations, nor may you export the API, SDK or
                    Service in violation of any applicable export laws and regulations. You may not use the API, SDK or
                    Service to defraud any third party or to distribute obscene or other unlawful materials or
                    information. For the avoidance of doubt, you may not use the API or SDK on a standalone business or
                    with any third party platform for commercial purposes. The SDK is not fault tolerant, and
                    accordingly, is not designed or intended for use in any software, application or situation where
                    failure or fault of any kind could lead to death or serious bodily injury of any person, or to
                    severe physical, environmental or property damage (“High Risk Use”). The SDK may only be used to
                    develop applications permitted hereunder that do not involve High Risk Use. High Risk Use includes,
                    without limitation, aircraft navigation, military and industrial use, control of nuclear, chemical
                    facilities, and of other modes of human mass transportation, as well as medical, surgical, or other
                    use intended to support or sustain life.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Account Creation. In order to access the API and/or SDK, you must register for a developer account
                    (“Account”) with Open Sesame and provide certain information about yourself, your Developer
                    Application, and/or your business, including a valid credit card (Visa, MasterCard or any other
                    issuer accepted by us) or PayPal account (“Payment Method”) of a payment provider (“Payment
                    Provider”) for purposes of paying Fees in accordance with Section 11. You represent and warrant
                    that: (a) all Account information you submit is truthful and accurate, and (b) you will maintain the
                    accuracy of such information. You may delete your Account at any time, for any reason, from the
                    “Account” page on the Site. Open Sesame may suspend or terminate your Account for any reason or no
                    reason at all, at any time. You agree that we can use, store, and share your account information as
                    permitted in Open Sesame’s Privacy Policy. In order to cooperate with legitimate governmental
                    requests, subpoenas or court orders, or to protect our business and customers, we may access and
                    disclose any information that we consider necessary or appropriate, including your Account username
                    and password, IP address and traffic information, and usage history.b.Account Responsibilities. You
                    are responsible for maintaining the confidentiality of your Account login information and for all
                    activities that occur under your Account. You agree to immediately notify Open Sesame of any
                    unauthorized use, or suspected unauthorized use of your Account or any other breach of security.
                    Open Sesame cannot and will not be liable for any loss or damage arising from your failure to comply
                    with this Section. After you have successfully created your Account, you will be issued one or more
                    unique security keys, secrets, tokens, access codes, passwords or other credentials (collectively,
                    “Keys”). All activities that occur using your Keys are your responsibility. You are not permitted to
                    disclose, sell, transfer, or sublicense your Keys to anyone else.c.End User Accounts. You agree that
                    you are not permitted to create End User (defined below) accounts via the Open Sesame API or SDK on
                    behalf of your End Users. Each End User must manually create and authenticate their Open Sesame
                    account within your application or via a link to Open Sesame provided within your application for
                    the End User to be able to take advantage of the Service. You shall not obtain End User passwords to
                    the Service under any circumstance and shall only use Open Sesame’s designated approval methods for
                    obtaining access to or permission to access End User data. If you would like to manage accounts on
                    behalf of your End Users, you must create an enterprise developer account (“Enterprise Developer
                    Account”) that will provide you with access to End User account creation and management tools.
                    Performing such activities and creating an Enterprise Developer Account requires a separate
                    Enterprise SDK License, which is directly negotiated with Open Sesame. Please reach out us using
                    using{' '}
                    <span className="contact" onClick={handleContact}>
                      Contact Form
                    </span>{' '}
                    for more information.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Audit and Monitoring. You agree to provide us with access to your Developer Application(s) and/or
                    other materials related to your use of the API and SDK as may be requested by us to verify your
                    compliance with this Agreement, to audit your use, display and storage of the API and SDK. You agree
                    that we may crawl or otherwise remotely monitor such items related to your use of the API and SDK
                    and you agree not to block or interfere with such efforts.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    End Users. You may distribute those components of the API and SDK that are expressly identified in
                    the documentation as intended for distribution, solely as integrated in your Developer Application.
                    You will ensure that any end user obtaining access to a Developer Application which uses the API or
                    SDK (each, an “End User”) consents to the collection of End User data pursuant to Section 6 and
                    agrees to be subject to the Open Sesame Terms of Service found at https://sync-stage.com/terms/. You
                    further agree that with respect to End Users and their accounts, you will not encourage or create
                    functionality in your Developer Application that allows End Users to violate the Open Sesame Terms
                    of Service.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Data. a.Data Collection. Open Sesame collects data in connection with the API, SDK and Service in
                    accordance with our Privacy Policy found at https://sync-stage.com/policy/ and Terms & Conditions
                    found at https://sync-stage.com/terms/, which may include data (including audio data) from End
                    User(s) that passes through or is captured by the API, SDK, and/or Service (“End User Data”). We may
                    use the End User Data to provide the Service and on an aggregated, de-identified basis to improve
                    the Service. By using the API and/or SDK, you hereby agree to such data collection and use and agree
                    to obtain End User consent for such data collection and use. Additionally, if Open Sesame agrees to
                    provide you with any support services, you hereby consent to the collection of any data by Open
                    Sesame related to the API and SDK, your use of the Service, and any applicable End Users’ use of the
                    Service in order to provide support services. As between you and Open Sesame, Open Sesame retains
                    all worldwide right, title and interest in and to End User data, including without limitation, all
                    data associated with the content within the functionality enabled by the API and/or SDK (e.g. social
                    sharing features and message content and metadata); provided, however, that End User retains all
                    ownership rights to and over their Contributions, as that term is defined in our Terms & Conditions
                    found at https://sync-stage.com/terms/. If you have access to End User data through the API or SDK
                    (“Captured End User Data”), you may not collect, use and/or disclose such Captured End User Data
                    except as explicitly permitted under Agreement and in accordance with our Privacy Policy found at
                    https://sync-stage.com/policy/. In addition, you may not (a) sell, license or otherwise
                    commercialize any Captured End User Data, (b) use Captured End User Data to target End Users for
                    marketing or advertising purposes, or (c) disclose Captured End User Data to any ad network, data
                    broker or other advertising or monetization related service. Any data, content and information you
                    provide or submit for use with the Service (e.g. title, image, and description of your Application)
                    (“Developer Data”) and all worldwide intellectual property rights in it, is your exclusive property.
                    You grant Open Sesame a non-exclusive, worldwide, royalty-free and fully paid license (a) to use the
                    Developer Data as necessary for purposes of providing and improving the Service during the term of
                    this Agreement, (b) to use your trademarks, service marks, and logos as required to provide the
                    Service during the term of this Agreement, and (c) perpetually use the Developer Data in an
                    aggregated and anonymized form to: (i) improve the Service and Open Sesame’s related products and
                    services; (ii) provide analytics and benchmarking services; and (iii) generate and disclose
                    statistics regarding use of the Service, provided, however, that such statistics may not identify
                    you individually. b.Data Storage. Unless expressly agreed to by Open Sesame in writing elsewhere, we
                    may, but have no obligation to, store any of the data or content that you or End Users upload, post,
                    e-mail, transmit or otherwise make available through the API or SDK or data that is otherwise
                    collected by or related to the Services and your or End Users’ use thereof (collectively, “Your
                    Content”). We have no responsibility or liability for the deletion or accuracy of Your Content; the
                    failure to store, transmit or receive transmission of Your Content; or the security, privacy,
                    storage, or transmission of other communications originating with or involving use of Services.
                    Certain Services may enable you to specify the level at which such Services restrict access to Your
                    Content. You are solely responsible for applying the appropriate level of access to Your Content. If
                    you do not choose, the system may default to its most permissive setting. You agree that Open Sesame
                    retains the right to create reasonable limits on Open Sesame’s storage of Your Content, such as
                    limits on file size, storage space, processing capacity, and similar limits described on the Website
                    and as otherwise determined by Open Sesame in its sole discretion.c.Data Processing Agreement.
                    Before providing to Open Sesame or enabling Open Sesame to Process any Personal Data that is subject
                    to the California Consumer Privacy Act (“CCPA”) or the European Union’s General Data Protection
                    Regulation (“GDPR”), you will enter into a Data Processing Agreement (“DPA”). If you have not
                    entered into such DPA, you represents, warrants and covenants that no Personal Data Processed by
                    Company under this Agreement is subject to the CCPA or GDPR. Any Personal Data that is subject to
                    the CCPA or GDPR shall be governed by the DPA and shall not be considered confidential information
                    governed by Section 13 of this Agreement. In the event of a conflict between any provision of the
                    DPA and this Agreement, the provision providing the higher level of privacy or data protection shall
                    govern. As used herein, “Personal Data” means any information relating to an identified or
                    identifiable natural person, and all information that is “personal data,” “personal information,”
                    “personally identifiable information.” Personal Data specifically excludes all data or information
                    that constitutes “protected health information” as defined in 45 C.F.R. 160.103. “Processing”
                    (including “Process” and other variants of the term) means any operation or set of operations that
                    is performed upon Personal Data, whether or not by automatic means, such as collection, collation,
                    recording, organization, storage, adaptation or alteration, retrieval, consultation, analysis,
                    interpretation, compilation, aggregation, use, disclosure by transmission, dissemination, viewing,
                    copying, deleting, or otherwise making available, alignment or combination, blocking or erasure, or
                    destruction.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Security. You are solely responsible for applying appropriate security safeguards designed to
                    protect your Developer Application and End Users and other data within your possession or control.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Copyright and Attribution Notices. You must include all copyright and other proprietary rights
                    notices that accompany the API and/or SDK. You agree to include a conspicuous “Powered by SyncStage”
                    attribution notice on your Developer Application that accesses or uses the Service, API or SDK. In
                    the End User account login section of your Developer Application, you agree to include the Open
                    Sesame logo and follow the Open Sesame branding guidelines provided on the Developer Portal. Open
                    Sesame hereby grants you a non-exclusive, nontransferable license to use Open Sesame’s trademarks,
                    trade names and logos, solely as may be provided by Open Sesame to you, and for use solely for
                    attribution purposes.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Proprietary Rights. Open Sesame retains all rights, title, and interest to the Site, API, SDK, and
                    Service, including without limitation, all intellectual property rights therein and all derivative
                    works thereto. Subject always to our ownership of the API and SDK, you will be the sole and
                    exclusive owner of any Developer Application developed by you that accesses or uses the API or SDK,
                    excluding the Service, API and SDK and any portions thereof. You understand and acknowledge that the
                    software, code, proprietary methods and systems that make up the API and SDK are: (i) copyrighted by
                    us and/or our licensors under United States and international copyright laws; (ii) subject to other
                    intellectual property and proprietary rights and laws; and (iii) owned by us or our licensors. You
                    must abide by all copyright notices, information, or restrictions contained in or attached to the
                    API and SDK. If you are a U.S. Government user, any of the components that constitute the API and
                    SDK and their related documentation is a &ldquo;commercial item&ldquo; as that term is defined at 48
                    C.F.R. 2.101, consisting of “commercial computer software” and “commercial computer software
                    documentation” as such terms are used in 48 C.F.R. 12.212. Consistent with 48 C.F.R. 12.212 and 48
                    C.F.R. 227.7202-1 through 227.7202-4, all U.S. Government users acquire the API and/or SDK and any
                    documentation provided with the API and/or SDK with only those rights set forth in this Agreement.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Feedback. In the event that you provide us any ideas, thoughts, criticisms, suggested improvements
                    or other feedback related to the Site, API, SDK, or the Service, (collectively
                    &ldquo;Feedback&ldquo;), you agree we may use the Feedback to modify our products and services and
                    that you will not be due any compensation, including any royalty related to the product or service
                    that incorporates your Feedback. You grant to us a worldwide, royalty-free, fully paid, perpetual,
                    irrevocable license to use, reproduce, modify, translate, distribute, perform, display, import,
                    sell, offer for sale, make, have made and otherwise exploit the Feedback in any form, media, or
                    technology, whether now known or hereafter developed, and to allow others to do the same.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Fees and Payment. a.Fees. In consideration for the access and use rights granted herein, you agree
                    to pay to Open Sesame fees based on the cumulative synchronized audio stream minutes run through the
                    Services (each a “Usage Minute”) across all of your Developer Applications, in accordance with Open
                    Sesame’s then-current rates (the “Fees”). Open Sesame’s current rates and additional details
                    regarding calculation of Usage Minutes can be found at https://sync-stage.com/pricing/ (“Pricing
                    Page”), as may be updated from time to time at Open Sesame’s sole discretion. Open Sesame reserves
                    the right to change the Fees or applicable charges and to institute new charges or Fees upon thirty
                    (30) days’ prior notice to you (which may be sent by email). Fees for the first 400 Usage Minutes
                    (or other amount mutually agreed upon in writing) shall be waived, after which Fees shall be charged
                    as set forth on the Pricing Page. Except as explicitly set forth on the Pricing Page, all Fees are
                    non-refundable. b.Payment Terms. Your Payment Provider agreement governs your use of the designated
                    credit card or PayPal account, and you must refer to that agreement, not this Agreement, to
                    determine your rights and liabilities. By providing Open Sesame with your Payment Method and
                    associated payment information, you agree that we are authorized to immediately invoice your Account
                    for all Fees and charges due and payable to Open Sesame hereunder and that no additional notice or
                    consent is required. You agree to maintain current Payment Method information and to immediately
                    notify us of any change in your billing address or Payment Method used for payment hereunder.
                    c.Taxes. The payments required under this Section 11 (Fees and Payment) do not include any Sales Tax
                    that may be due in connection with the services provided under this Agreement. If we determine we
                    have a legal obligation to collect a Sales Tax from you in connection with this Agreement, we shall
                    collect such Sales Tax in addition to the Fees required under this Agreement. If any Services, or
                    payments for any Services, under the Agreement are subject to any Sales Tax in any jurisdiction and
                    you have not remitted the applicable Sales Tax to Open Sesame, you will be responsible for the
                    payment of such Sales Tax and any related penalties or interest to the relevant tax authority, and
                    you will indemnify Open Sesame for any liability or expense we may incur in connection with such
                    Sales Taxes. Upon Open Sesame’s request, you will provide official receipts issued by the
                    appropriate taxing authority, or other such evidence that you have paid all applicable taxes. For
                    purposes of this section, “Sales Tax” shall mean any sales or use tax and any other tax measured by
                    sales proceeds that is the functional equivalent of a sales tax where the applicable taxing
                    jurisdiction does not otherwise impose a sales or use tax
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    General Rules of Conduct. You agree not to use the API or SDK in a manner that:(a) Conducts or
                    promotes any illegal activities;(b) Loads or transmits any form of virus, worm, Trojan horse, or
                    other malicious code;(c) Violates any applicable federal, state, or local law or regulation;(d)
                    Violates any agreement, terms of service or privacy policy;(e) Uses any automatic, electronic or
                    manual process to access, search or harvest information from an individual.(f) Interferes with the
                    performance or functionality of the Service; including attempting to exceed or circumvent
                    limitations on access to and use of the SDK or API, exceed or circumvent any limitation on the API
                    calls you may make, or otherwise use the SDK or API in a manner that exceeds reasonable request
                    volume, or constitutes excessive or abusive usage (“Rate Limits”). If Open Sesame, in its sole
                    discretion, determines that you have attempted to exceed or circumvent Rate Limits, or other
                    controls that limit use of the SDK or API, then your ability to use the SDK or API may be
                    temporarily suspended or permanently blocked.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Confidential Information. You will safeguard, protect, respect, and maintain as confidential the API
                    and SDK, including without limitation, the underlying computer code and documentation to which you
                    may obtain or receive access, the reference application source code, and the functional or technical
                    design, logic, or other internal routines or workings of the SDK, which are considered confidential
                    and proprietary to Open Sesame. You agree not to reproduce, disseminate, sell, distribute or
                    commercially exploit any such confidential information of Open Sesame in any manner. These
                    non-disclosure obligations shall survive termination or expiration of this Agreement.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Geographical Restrictions. We make no representation that any of the SDK or API is appropriate or
                    available for use in locations outside the United States. If you choose to use the API or SDK, you
                    do so on your own initiative and are responsible for compliance with local laws.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Modification of the API and SDK. We reserve the right to modify or discontinue the API and SDK with
                    or without notice to you. We will not be liable to you or any third party should we exercise our
                    right to modify or discontinue the API and SDK. If you object to any such changes, your sole
                    recourse will be to cease use of the API and/or SDK. YOU AGREE THAT WE WILL NOT BE LIABLE TO YOU OR
                    ANY OTHER PARTY FOR ANY TERMINATION OF YOUR ACCESS TO THE API AND/OR SDK. You may be required to use
                    the most recent version of the API or SDK in order to obtain certain functionality. Each version of
                    the API and SDK only supports the platforms and contains the functionalities described in the
                    documentation for such version of the API or SDK.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Termination. You acknowledge and agree that we, at our sole discretion, may terminate your use of
                    the API, SDK and/or Service (with respect to all or a subset of your Developed Applications) without
                    prior notice for any reason at any time. You agree that we shall not be liable to you or any third
                    party for termination of your access to the SDK and Service. In the event of any termination, you
                    will immediately cease use of the SDK and Service.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    DISCLAIMERS. THE API AND SDK AS WELL AS THE SERVICE, SOFTWARE, MATERIALS, AND TECHNOLOGY PROVIDED IN
                    CONNECTION WITH THIS AGREEMENT, ARE PROVIDED ON AN &ldquo;AS IS&ldquo; AND &ldquo; AS
                    AVAILABLE&ldquo; BASIS WITHOUT ANY WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY LAW,
                    OPEN SESAME, OUR OFFICERS, DIRECTORS, AGENTS, SUPPLIERS, AND EMPLOYEES EXPRESSLY DISCLAIM ALL
                    REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS OR IMPLIED, ORAL OR WRITTEN, INCLUDING ANY
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, ACCURACY, TITLE,
                    QUIET ENJOYMENT, NON-INTERRUPTION, AND/OR SYSTEM INTEGRATION. OPEN SESAME, OUR OFFICERS, DIRECTORS,
                    AGENTS, SUPPLIERS, AND EMPLOYEES MAKE NO WARRANTY ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS,
                    QUALITY, OR TIMELINESS OF THE API, SDK AND SERVICE, OR THAT PROBLEMS WITH THE FOREGOING WILL BE
                    CORRECTED, OR THAT THE API AND SDK ARE FREE OF VIRUSES AND OTHER HARMFUL COMPONENTS, OR THAT THEY
                    WILL BE UNINTERRUPTED OR ERROR FREE.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    LIMITATIONS OF LIABILITY. YOU ACKNOWLEDGE AND AGREE THAT WE ARE ONLY WILLING TO PROVIDE ACCESS TO
                    THE API AND SDK IF YOU AGREE TO CERTAIN LIMITATIONS OF LIABILITY TO YOU AND TO THIRD PARTIES. IN NO
                    EVENT WILL OPEN SESAME, ITS AFFILIATES, OFFICERS, DIRECTORS, AGENTS, SUPPLIERS OR EMPLOYEES BE
                    LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR
                    LIKE DAMAGES, LOST PROFITS, GOODWILL, LOST OPPORTUNITIES AND INTANGIBLE LOSSES, LOST DATA, LOST
                    EMPLOYMENT OPPORTUNITIES, OR BUSINESS INTERRUPTIONS ARISING IN CONNECTION WITH THE API, SDK,
                    SERVICE, OR THIS AGREEMENT, OR RESULTING FROM THE USE OR ACCESS TO, OR THE INABILITY TO USE OR TO
                    USE THE API, SDK OR SERVICE. THESE LIMITATIONS OF LIABILITY APPLY REGARDLESS OF THE NATURE OF ANY
                    CLAIM, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL OR EQUITABLE THEORY, AND
                    WHETHER OR NOT OPEN SESAME IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE MAXIMUM EXTENT
                    PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU
                    FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS
                    OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO $100. THE EXISTENCE OF MORE THAN ONE
                    CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND
                    ARISING FROM OR RELATING TO THIS AGREEMENT.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    DISCLAIMER AND LIABILITY LIMITATION. SOME JURISDICTIONS DO NOT ALLOW
                    THEEXCLUSIONOFCERTAINWARRANTIESORTHELIMITATIONOREXCLUSIONOFLIABILITYFORCERTAIN TYPES OF DAMAGES.
                    ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS
                    ANDDISCLAIMERSMAYNOTAPPLYTOYOU.TOTHEEXTENTTHATWEMAYNOT,ASAMATTEROFAPPLICABLE LAW, DISCLAIM ANY
                    WARRANTY OR LIMIT OUR LIABILITIES, THE SCOPE
                    ANDDURATIONOFSUCHWARRANTYANDTHEEXTENTOFOURLIABILITYWILLBETHEMINIMUMPERMITTEDUNDERSUCHAPPLICABLELAW.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    FORCE MAJEURE. WITHOUT LIMITING THE FOREGOING, UNDER NO CIRCUMSTANCES WILL WE OR OUR SUPPLIERS BE
                    HELD LIABLE FOR ANY DELAY OR FAILURE IN PERFORMANCE RESULTING DIRECTLY OR INDIRECTLY FROM ACTS OF
                    NATURE, FORCES, OR CAUSES BEYOND OUR REASONABLE CONTROL, INCLUDING, WITHOUT LIMITATION, INTERNET
                    FAILURES, COMPUTER EQUIPMENT FAILURES, TELECOMMUNICATION EQUIPMENT FAILURES, OTHER EQUIPMENT
                    FAILURES, ELECTRICAL POWER FAILURES, STRIKES, LABOR DISPUTES, RIOTS, INSURRECTIONS, CIVIL
                    DISTURBANCES, SHORTAGES OF LABOR OR MATERIALS, FIRES, FLOODS, STORMS, EXPLOSIONS, ACTS OF GOD,
                    TERRORISM, WAR, GOVERNMENTAL ACTIONS, ORDERS OF DOMESTIC OR FOREIGN COURTS OR TRIBUNALS, OR
                    NON-PERFORMANCE OF THIRD PARTIES
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Indemnification. You agree to indemnify, defend and hold harmless Open Sesame, our officers,
                    directors, and other partners, employees, consultants and agents, from and against any and all
                    third-party claims, liabilities, damages, losses, costs, expenses, fees (including reasonable
                    attorneys&apos; fees and court costs) that such parties may incur as a result of or arising from (i)
                    any data you or your End Users submit, post or transmit through the API or SDK, (ii) your use of the
                    API or SDK, (iii) your violation of this Agreement, (iv) your violation of any rights of any other
                    person or entity, or (v) any viruses, Trojan horses, worms, time bombs, cancelbots or other similar
                    harmful or deleterious programming routines input by you into the API or SDK.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Electronic Communications. For contractual purposes, you (i) consent to receive communications from
                    us in an electronic form; and (ii) agree that all terms and conditions, agreements, notices,
                    documents, disclosures, and other communications (&ldquo;Communications&ldquo;) that we provide to
                    you electronically satisfy any legal requirement that such Communications would satisfy if it were
                    in a writing. Your consent to receive Communications and do business electronically, and our
                    agreement to do so, applies to all of your interactions and transactions with us. The foregoing does
                    not affect your non-waivable rights. You may withdraw your consent to receive Communications
                    electronically by contacting us using{' '}
                    <span className="contact" onClick={handleContact}>
                      Contact Form
                    </span>
                    . If you withdraw your consent, from that time forward, you must stop using the API and/or SDK, as
                    applicable. The withdrawal of your consent will not affect the legal validity and enforceability of
                    any obligations or any electronic Communications provided or business transacted between us prior to
                    the time you withdraw your consent. Please keep us informed of any changes in your email or mailing
                    address so that you continue to receive all Communications without interruption.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Publicity. Open Sesame may identify you as one of our developers in general promotional literature
                    and disclose to current and prospective customers that you are a user of the API and/or SDK. You
                    hereby grant to Open Sesame permission to include your name and corporate logo, if applicable, in
                    Open Sesame’s promotional materials and on the Site.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    Dispute Resolution. Please read the following arbitration agreement in this Section (“Arbitration
                    Agreement”) carefully. It requires you to arbitrate disputes with Open Sesame and limits the manner
                    in which you can seek relief from us.a.Applicability of Arbitration Agreement. All claims and
                    disputes (excluding claims for injunctive or other equitable relief as set forth below) in
                    connection with this Agreement or the use of the API and/or SDK that cannot be resolved informally
                    or in small claims court will be resolved by binding confidential arbitration on an individual basis
                    under the terms of this Arbitration Agreement. Unless otherwise agreed to, all arbitration
                    proceedings will be held in English. This Arbitration Agreement applies to you and Open Sesame, and
                    to any subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and
                    assigns, as well as all authorized or unauthorized users or beneficiaries of services or goods
                    provided under this Agreement.b.Notice Requirement and Informal Dispute Resolution. Before either
                    party may seek arbitration, the party must first send to the other party a written notice of dispute
                    (“Notice”) describing the nature and basis of the claim or dispute, and the requested relief. A
                    Notice to Open Sesame should be sent to: 4676 Admiralty Way, Suite 503 Marina Del Rey, CA 90292.
                    After the Notice is received, you and Open Sesame may attempt to resolve the claim or dispute
                    informally. If you and Open Sesame do not resolve the claim or dispute within thirty (30) days after
                    the Notice is received, either party may begin an arbitration proceeding. The amount of any
                    settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator
                    has determined the amount of the award, if any, to which either party is entitled.c.Arbitration
                    Rules. Arbitration will be initiated through the American Arbitration Association (“AAA”), an
                    established alternative dispute resolution provider (“ADR Provider”) that offers arbitration as set
                    forth in this section. If AAA is not available to arbitrate, the parties will agree on an
                    alternative ADR Provider. The rules of the ADR Provider will govern all aspects of the arbitration,
                    including the method of initiating and/or demanding arbitration, except to the extent such rules are
                    in conflict with the Terms. The AAA Consumer Arbitration Rules (“Arbitration Rules”) governing the
                    arbitration are available online at adr.org or by calling the AAA at 1-800-778-7879. The arbitration
                    will be conducted by a single, neutral arbitrator. Any claims or disputes where the total amount of
                    the award sought is less than Ten Thousand U.S. Dollars (US $10,000.00) may be resolved through
                    binding non-appearance-based arbitration, at the option of the party seeking relief. For claims or
                    disputes where the total amount of the award sought is Ten Thousand U.S. Dollars (US $10,000.00) or
                    more, the right to a hearing will be determined by the Arbitration Rules. Any hearing will be held
                    in a location within 100 miles of your residence, unless you reside outside of the United States,
                    and unless the parties agree otherwise. If you reside outside of the U.S., the arbitrator will give
                    the parties reasonable notice of the date, time and place of any oral hearings. Any judgment on the
                    award rendered by the arbitrator may be entered in any court of competent jurisdiction. If the
                    arbitrator grants you an award that is greater than the last settlement offer that Open Sesame made
                    to you prior to the initiation of arbitration, Open Sesame will pay you the greater of the award or
                    $2,500.00. Each party will bear its own costs (including attorney’s fees) and disbursements arising
                    out of the arbitration and will pay an equal share of the fees and costs of the ADR
                    Provider.d.Additional Rules for Non-Appearance Based Arbitration. If non-appearance based
                    arbitration is elected, the arbitration will be conducted by telephone, online and/or based solely
                    on written submissions; the specific manner will be chosen by the party initiating the arbitration.
                    The arbitration will not involve any personal appearance by the parties or witnesses unless
                    otherwise agreed by the parties.e.Time Limits. If you or Open Sesame pursue arbitration, the
                    arbitration action must be initiated and/or demanded within the statute of limitations (i.e., the
                    legal deadline for filing a claim) and within any deadline imposed under the AAA Rules for the
                    pertinent claim.f.Authority of Arbitrator. If arbitration is initiated, the arbitrator will decide
                    the rights and liabilities, if any, of you and Open Sesame, and the dispute will not be consolidated
                    with any other matters or joined with any other cases or parties. The arbitrator will have the
                    authority to grant motions dispositive of all or part of any claim. The arbitrator may award
                    monetary damages, and grant any non-monetary remedy or relief available to an individual under
                    applicable law, the AAA Rules, and this Agreement. The arbitrator will issue a written award and
                    statement of decision describing the essential findings and conclusions on which the award is based,
                    including the calculation of any damages awarded. The arbitrator has the same authority to award
                    relief on an individual basis that a judge in a court of law would have. The award of the arbitrator
                    is final and binding upon you and Open Sesame.g.Waiver of Jury Trial. THE PARTIES HEREBY WAIVE THEIR
                    CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY,
                    instead electing that all claims and disputes will be resolved by arbitration under this Arbitration
                    Agreement. Arbitration procedures are typically more limited, more efficient and less costly than
                    rules applicable in a court and are subject to very limited review by a court. In the event any
                    litigation should arise between you and Open Sesame in any state or federal court in a suit to
                    vacate or enforce an arbitration award or otherwise, YOU AND OPEN SESAME WAIVE ALL RIGHTS TO A JURY
                    TRIAL, instead electing that the dispute be resolved by a judge.h.Waiver of Class or Consolidated
                    Actions. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED
                    OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE
                    USER/DEVELOPER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER
                    USER/DEVELOPER.i.Confidentiality. All aspects of the arbitration proceeding, including the award of
                    the arbitrator and compliance therewith, will be strictly confidential. The parties agree to
                    maintain confidentiality unless otherwise required by law. This paragraph will not prevent a party
                    from submitting to a court of law any information necessary to enforce this Arbitration Agreement or
                    an arbitration award, or to seek injunctive or equitable relief.j.Severability. If any part of this
                    Arbitration Agreement are found to be invalid or unenforceable by a court of competent jurisdiction,
                    then such specific part or parts will be of no force and effect and will be severed and the
                    remainder of the Arbitration Agreement will continue in full force and effect.k.Right to Waive. Any
                    or all of the rights and limitations set forth in this Arbitration Agreement may be waived by the
                    party against whom the claim is asserted. Such waiver will not waive or affect any other portion of
                    this Arbitration Agreement.l.Survival of Agreement. This Arbitration Agreement will survive the
                    termination of your relationship with Open Sesame. m. Small Claims Court. Notwithstanding the
                    foregoing, either you or Open Sesame may bring an individual action in small claims
                    court.n.Emergency Equitable Relief. Notwithstanding the foregoing, either party may seek emergency
                    equitable relief before a state or federal court in order to maintain the status quo pending
                    arbitration. A request for interim measures will not be deemed a waiver of any other rights or
                    obligations under this Arbitration Agreement.o.Claims Not Subject to Arbitration. Notwithstanding
                    the foregoing, claims of defamation, violation of the Computer Fraud and Abuse Act, and infringement
                    or misappropriation of the other party’s patent, copyright, trademark or trade secrets will not be
                    subject to this Arbitration Agreement.p.Venue for Litigation. In any circumstances where the
                    foregoing Arbitration Agreement permits the parties to litigate in court, the parties hereby agree
                    to submit to the personal jurisdiction of the state and federal courts located within Los Angeles
                    County, California, for such purpose.
                  </span>
                </li>
                <li className="c1 c3 li-bullet-0">
                  <span className="c0">
                    General Terms. You are responsible for compliance with all applicable laws. This Agreement is
                    personal to you, and you may not transfer, assign or delegate your right and/or duties under this
                    Agreement to anyone else and any attempted assignment or delegation is void. The paragraph headings
                    in this Agreement, shown in boldface type, are included only to help make this Agreement easier to
                    read and have no binding effect. Any delay or failure by us to exercise or enforce any right or
                    provision of this Agreement will not constitute a waiver of such right or provision. No waiver by us
                    will have effect unless such waiver is set forth in writing, signed by us; nor will any such waiver
                    of any breach or default constitute a waiver of any subsequent breach or default. This Agreement
                    constitutes the complete and exclusive agreement between you and us with respect to the subject
                    matter hereof, and supersedes all prior oral or written understandings, communications or
                    agreements. If for any reason a court of competent jurisdiction finds any provision of this
                    Agreement, or portion thereof, to be unenforceable, that provision of this Agreement will be
                    enforced to the maximum extent permissible so as to effect the intent of the parties, and the
                    remainder of this Agreement will continue in full force and effect.
                  </span>
                </li>
              </ol>
            </React.Fragment>
          </Text>
        </Box>
      </Container>
    </TermsSectionWrapper>
  );
};

TermsSection.propTypes = {
  title: PropTypes.object,
  content: PropTypes.object,
  row: PropTypes.object,
};

TermsSection.defaultProps = {
  title: {
    fontSize: ['20px', '24px', '26px', '30px', '30px'],
    fontWeight: 'bold',
    color: '#fff',
    pt: ['40px', '60px', '60px', '60px', '60px'],
    pb: ['15px', '25px', '25px', '25px', '25px'],
    lineHeight: '1',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  content: {
    fontSize: ['14px', '16px', '18px', '18px', '18px'],
    fontWeight: 'normal',
    color: '#fff',
    pb: ['50px', '75px', '75px', '75px', '75px'],
    lineHeight: '1.5',
    fontStyle: 'normal',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
};

export default TermsSection;
